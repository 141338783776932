import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ApplicantInterviewFormService } from './applicant-interview-form.service';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { first } from 'rxjs/operators';
import { UserDataService } from '@discoverer/app-core';
import { DiscussionNotesService } from '../../../services/apis/discussion-note.service';
import { IDiscussionNoteDto } from '../../../../../models/dtos/discussion-note.dto';
import { ApplicantService } from '../../../services/apis/applicant.service';
import { IApplicantDto } from '@app/ATS/models/dtos/applicant.dto';
import { IApplicantInterviewDto } from '@app/ATS/models/dtos/applicant-interview.dto';
@Component({
    selector: 'app-interview-form',
    templateUrl: './applicant-interview-form.component.html',
    styleUrls: ['./applicant-interview-form.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: ApplicantInterviewFormService },
    ]
})
export class ApplicantInterviewFormComponent {
    public user: any;

    @Input()
    public model: any = {};

    @Input()
    formKey: string;

    @Input()
    appId = 'ats';

    @Input()
    tabId = 'applicants';
    public isLoading = false;

    @Output() isSubmitted = new EventEmitter();

    @ViewChild('dynForm', { static: false }) dynForm;

    public isEditMode = false;

    constructor(
        private _userService: UserDataService,
        private _applicantService: ApplicantService) {
        this._userService.loggedInUser.subscribe((userData) => this.user = userData);
    }

    public save() {
        this.isLoading = true;

        if (!this.dynForm.form.valid) {
            this.isLoading = false;
        }

        this.dynForm.submit();
    }

    public async onSubmit(interview: IApplicantInterviewDto) {
        if (interview) {
            this.isLoading = true;
            this.isEditMode = true;
            let listId = this.model.logListId;

            var notes: IDiscussionNoteDto[] = [{
                body: 'Edited Interview Information',
                notesListId: listId,
                createdOn: new Date().toString(),
                createdById: +this.user.id
            }];

            this._applicantService.createApplicantNotesForLog(this.model.applicantId, listId, notes).subscribe(x => {
                this.isSubmitted.emit(true);
                this.isLoading = false;
                this.isEditMode = false;
            });
        }
        else
            this.isLoading = false;

    }
}
