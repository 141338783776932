import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'dyn-hyper-link',
  template: `
  <ng-container *ngIf="!to.disabled">
  <input
      *ngIf="to.type !== 'number'; else numberTmp"
      matInput
      [id]="id"
      [type]="'type' || 'text'"
      [readonly]="to.readonly"
      [required]="to.required"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [tabIndex]="to.tabindex"
      [placeholder]="to.placeholder"
    />
   
<ng-template #numberTmp>
  <input
    matInput
    [id]="id"
    [type]="'number'"
    [readonly]="to.readonly"
    [required]="to.required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [tabIndex]="to.tabindex"
    [placeholder]="to.placeholder"
  />
  </ng-template>
</ng-container>

<ng-container *ngIf="to.disabled">
  <a 
    [href]="to.href ? to.href : model[field.key.toString()]" 
    (click)="to.href ? onClick(to.href) : null">
    {{ model[field.key.toString()] }}
  </a>
</ng-container>
`,
  styleUrls: ['./formly-hyper-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyHyperLinkComponent extends FieldType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
  onClick(url: string): void {
    const validUrl = this.getValidHref(url);
    if (validUrl) {
      window.open(validUrl, '_blank'); 
    } else {
      console.warn('Invalid URL:', url);  
    }
  }
  getValidHref(url: string): string | null {
    return this.isValidUrl(url) ? url : null;
  }
  private isValidUrl(url: string): boolean {
    return url && /^(https?:\/\/|whatsapp:\/\/)/.test(url);
  }

}
