import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import  _ from 'lodash';
import { map } from 'rxjs/operators';
import { IOption, Option } from '../../option-lists/models/option';
import { OptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { BaseCustomController } from '../shared/base-custom-controller';

//  Custom text field with extra classes and all
export interface IGroup {
  label: string;
  options: IOption[];
}

@Component({
  selector: 'app-formly-field-dyn-select',
  templateUrl: './dyn-select.component.html',
  styleUrls: ['./dyn-select.component.scss']
})
export class DynSelectComponent extends BaseCustomController implements OnInit {
  public groupedDynOptions: IGroup[];
  public dynOptions: IOption[] = [];
  public optionControl = new FormControl('');

  public separateGroupsList = false;
  public filteredOptions: IOption[] = [];
  public groups = [];
  public groupControl = new FormControl();
  public allGroups = [];
  public allOptions: IOption[] = [];
  public allFilteredOptions: IOption[] = [];
  public optionSearchControl = new FormControl();
  public groupSearchControl = new FormControl();
  public separateOptionsSearch = new FormControl();
  @ViewChild('searchText', { static: false }) searchRef: ElementRef;

  private _usedProp = 'id';

  constructor(
    private optionsService: OptionDataServiceInterface,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }
  ngOnInit() {
    this._usedProp = this.field.templateOptions.useCode ? 'code' : 'id';
    this.subscribeToOptionsSearch();
    this.subscribeToGroupsSearch();
    this.subscribeToSeparateOptionsSearch();
    if (this.field.templateOptions.optionsListId != null) {
      this.optionsService.getByOptionListId(this.field.templateOptions.optionsListId).subscribe(response => {
        let filterByGroup = this.field.templateOptions?.filterByGroup;
        this.dynOptions = this.allOptions = filterByGroup
            ? response.filter((option) => option.group === filterByGroup):response;
        if (this.field.templateOptions.showInGroups) {
          this.groupedDynOptions = this.groupOptions(response);
          this.groupedDynOptions = this.orderOptions(this.groupedDynOptions);
        }
        //this.field.templateOptions.separateGroupsList
        if (this.field.templateOptions.separateGroupsList) {
          this.separateGroupsList = true;
          this.groups = this.allGroups = this.getDistinctGroups(response);
          this.changeControlStatus(this.groupControl);
          const selectedVal = this.field.formControl.value;
          if (selectedVal) {
            const group = this.dynOptions.find(o => o[this._usedProp] === selectedVal).group;
            this.groupControl.setValue(group);
            this.filterByGroup(group);
          }
        }
        this.changeControlStatus(this.optionControl);
        // tslint:disable-next-line:no-string-literal
        if (!this.cdRef['destroyed']) {
          this.cdRef.markForCheck();
        }
      });
    }
    else {
      this.optionsService.getByQuery(this.field.templateOptions.query).subscribe(response => {
        this.dynOptions = this.allOptions = response;
        this.changeControlStatus(this.optionControl);
        if (!this.cdRef['destroyed']) {
          this.cdRef.markForCheck();
        }
      });
    }
    this.optionControl.setValue(this.field.formControl.value);

    this.field.formControl.valueChanges.subscribe(s => {
      if (this.separateGroupsList) {
        const selectedOption = this.dynOptions.find(o => o[this._usedProp] === s);
        let group = null;
        if (selectedOption) {
          group = selectedOption.group;
        }
        this.filterByGroup(group);
        this.groupControl.setValue(group);
      }
      this.optionControl.setValue(s);
    });
    this.field.formControl.statusChanges.subscribe(s => {
      this.changeControlStatus(this.optionControl);
      if (this.separateGroupsList) {
        this.changeControlStatus(this.groupControl);
      }
    });
  }
  public get showInGroups() {
    let _showInGroups = false;
    _showInGroups = this.groupedDynOptions && this.groupedDynOptions.length > 1;
    return _showInGroups;
  }

  public changeSelection(selectionEvent: any) {
    this.formControl.markAsTouched();
    if (selectionEvent) {
      this.updateValueAndValidity(this.field, selectionEvent.value);
    } else {
      this.updateValueAndValidity(this.field, null);
    }
  }

  public get selectedOption() {
    return this.filteredOptions.find(op => op[this._usedProp] === this.optionControl.value);
  }

  compareObjects(o1: any, o2: any) {
    return !!o1?.id && !!o2?.id ? (o1.id === o2.id) : o1 === o2;
  }
  public getDisplayValue(option: IOption) {
    let displayVal = `${option.description} ${((this.to.showCode && option.code) ? ': ' + option.code : '')}`;
    if (option.group && this.field.templateOptions.showInGroups) {
      displayVal = `${option.group} - ${displayVal}`;
    }
    return displayVal;
  }

  public filterByGroup(group) {
    if (group) {
      this.filteredOptions = this.allFilteredOptions = this.dynOptions.filter(op => op.group === group);
    } else if (this.filteredOptions && this.filteredOptions.length) {
      this.filteredOptions = [];
      this.changeSelection(null);
      this.optionControl.setValue(null);
      this.groupControl.setValue(null)
    }
  }

  public focus() {
    this.searchRef.nativeElement.focus();
  }

  private subscribeToOptionsSearch() {
    this.optionSearchControl.valueChanges.pipe(
      map(value => this.search(this.allOptions, value))
    ).subscribe(x => {
      this.dynOptions = x;
      this.groupedDynOptions = this.orderOptions(this.groupOptions(x));
    });

  }

  private subscribeToGroupsSearch() {
    this.groupSearchControl.valueChanges.pipe(
      map(value => value ? this.allGroups?.filter(x => x.toLowerCase().includes(value.toLowerCase())) : this.allGroups)
    ).subscribe(x => this.groups = x);
  }

  private subscribeToSeparateOptionsSearch() {
    this.separateOptionsSearch.valueChanges.pipe(
      map(value => this.search(this.allFilteredOptions, value))
    ).subscribe(x => this.filteredOptions = x);
  }

  private search(options: IOption[], value) {
    return value ? options?.filter(x => x.description?.toLowerCase().includes(value?.toString().toLowerCase())) : options;
  }

  private changeControlStatus(control) {
    if (this.to && this.to.disabled) {
      control.disable();
    } else {
      control.enable();
    }
  }

  private groupOptions(options): IGroup[] {
    const result = _(options).groupBy('group').map((v, i) => {
      return { label: i, options: v };
    }).value();
    return result;
  }

  private orderOptions(groups) {
    const tempGroups = groups;
    tempGroups.forEach(group => {
      group.options.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
    });
    return tempGroups.sort((a, b) => a.label.localeCompare(b.label));
  }

  private getDistinctGroups(options) {
    return [...new Set(options.map(item => item.group))];
  }
}

