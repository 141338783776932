import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { buildFacetFilter, DiscovererQueryService, IDRFilter } from '@discoverer/core/services';

@Component({
    selector: 'disco-related-list-view',
    templateUrl: './disco-related-list-view.component.html',
    styleUrls: ['./disco-related-list-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoRelatedListViewComponent implements OnInit, OnChanges {

    @Input() public appId = '';
    @Input() public tabId = '';
    @Input() public visibleDetailsHeader = false;
    @Input() public requestId = null;

    @Input() public filterField: string = null;
    @Input() public filterValue: any = null;

    @Input() public enableNavigation: boolean = true;
    @Input() public enableMultiSelect: boolean = false;

    filters: { [key: string]: IDRFilter; } = {};

    constructor(
        public queryService: DiscovererQueryService,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.updateFilters();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterValue']) {
            this.updateFilters();
        }
    }

    private updateFilters(): void {
        console.log('filter' + this.filterValue)
        if (!!this.filterField && !!this.filterValue) {
            this.filters[this.filterField] = buildFacetFilter(this.filterField, [this.filterValue]);
            this.filters = { ...this.filters }
        } else {
            if (this.filterField) {
                delete this.filters[this.filterField];
                this.filters = { ...this.filters }
            }
        }
        this.cdRef.markForCheck();
    }

    public navigateToReport() {
        window.open(`${this.appId}/reports/${this.tabId}/${this.requestId}?${encodeURI(this.filterField)}=${encodeURI(this.filterValue)}`, this.requestId);
    }
}
