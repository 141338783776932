import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { FadeInOutOptimizedAnimation, heightExpand } from '@app/app.animation';


import { switchMap, filter, debounceTime, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { BaseController, DefaultPivotLimit, DiscovererQueryService, ICategories, IChartDataConfig, IChartFormatConfig, IChartState, IKanbanViewState, IMetric, IPivotConfig, ITab2, ITableHeader, ITableViewState, Permission, ReportPersistService, StateService, TabSettingsService, UserPermissionService } from '@discoverer/core/services';
import { IViewType, ViewTypes } from '../common';
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';
import { PopoverService } from '../popover/popover.service';

export class WorkspaceState {
    constructor(
        public selectedView = 'in',
        public mainQueryService: DiscovererQueryService
        ) {

    }
}

@Component({
    selector: 'workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
    animations: [FadeInOutOptimizedAnimation, heightExpand],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceComponent extends BaseController implements OnInit {

    drawerMode: 'over' | 'side' = 'over';
    drawerOpened: boolean = false;
    fixedInViewport = false;

    @Output()
    public rowClick: EventEmitter<any> = new EventEmitter<any>();

    @Input() public hideEditColumn: boolean;

    @Input() public enableSelect: boolean;

    @Input() public customTemplate: TemplateRef<any>;

    @Input() public hideActions: boolean;

    public currentTab: ITab2;
    public hideReports = true;
    public $workspaceState: ReplaySubject<WorkspaceState> = new ReplaySubject(null);
    public exportClicked = false;
    public tableHeaderConfig: ITableHeader;
    public directoryId = 0;

    public isReady = false;

    public AllViewTypes = ViewTypes;

    public canChangeReportType: boolean;
    public canEditFilters: boolean

    constructor(
        public reportPersist: ReportPersistService,
        public tableHeaderService: TableHeaderService,
        private _tabSettings: TabSettingsService,
        private _userPermissionService: UserPermissionService,
        private _popoverService: PopoverService,
    ) {
        super();
    }

    public closePopover() {
        this._popoverService.setState(true);
    }
    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();

        this.canChangeReportType = await this._userPermissionService
            .hasPermission(this.currentTab.key, Permission.ChangeReportType, `${this.reportPersist.loadReqId}`);

        this.canEditFilters = await this._userPermissionService
            .hasPermission(this.currentTab.key, Permission.EditFilters);

        this.subscriptions.push(
            this.reportPersist.oLastRequestData.pipe(
                filter(s => !!s), debounceTime(10),
                map(state => new WorkspaceState(state?.mainView?.type || 'table-view', this.reportPersist.mainQueryService))
            ).subscribe(this.$workspaceState)
        );
    }

}

