import { Injectable } from '@angular/core';
import { ITab2 } from '../classes/report-state';
import { ReplaySubject, Observable, combineLatest } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

import { AppSettingsService, IApp } from './app-settings.service';
import { HttpClient } from '@angular/common/http';
import { disLogger } from '../functions';
import { BaseController } from '../base-controller';
import { IColumn, IColumnSetting } from '../classes';
import { Factory } from '../core-data-services';


@Injectable()
export class TabSettingsService extends BaseController {

    public tabKey: string = '';
    public oCurrentTab: Observable<ITab2>;
    public oColumnSettings: Observable<IColumnSetting[]>;
    public oColumnDictionary: Observable<{ [key: string]: IColumnSetting }>;
    public oDefaultColumns: Observable<IColumnSetting[]>;
    public oDefaultFacetColumns: Observable<IColumnSetting[]>;
    public oDefaultSorts: Observable<{ dir: string, field: IColumnSetting }[]>;


    private $currentTab = new ReplaySubject<ITab2>(1);
    private $columnSettings = new ReplaySubject<IColumnSetting[]>(1);

    constructor(
        private httpClient: HttpClient,
        private appSettings: AppSettingsService) {
        super();

        this.oCurrentTab = this.$currentTab.asObservable();
        this.oColumnSettings = this.$columnSettings.asObservable();
        this.oDefaultColumns = this.$columnSettings.asObservable();
        this.oColumnDictionary = this.$columnSettings.pipe(map(t => {
            const dictionary: { [key: string]: IColumnSetting } = {};
            t.forEach(setting => dictionary[setting.fieldName] = setting);
            return dictionary;
        }));
        this.oDefaultFacetColumns = combineLatest([this.oColumnDictionary, this.oCurrentTab])
            .pipe(map(
                d => {
                    const dictionary = d[0];
                    const tab = d[1];
                    return tab.defaultFacets.map(field => dictionary[field]);
                }
            ));
        this.oDefaultSorts = combineLatest([this.oColumnDictionary, this.oCurrentTab])
            .pipe(map(
                d => {
                    const dictionary = d[0];
                    const tab = d[1];
                    return tab.defaultSorts.map(sortField => ({ dir: sortField.dir, field: dictionary[sortField.sortField] }));
                }
            ));


        this.whenCurrentTabIsSetLoadColumns();
    }

    public async setCurrentTab(tabKey: string) {
        this.tabKey = tabKey;
        const activeApp = await this.appSettings.oCurrentApp.pipe(take(1)).toPromise()
        const currentTab = this.appSettings.getCachedTabs(activeApp)?.find(t => t.key == tabKey)
        const tab = !currentTab ? await this.fetchTab(activeApp.key, tabKey) : currentTab;
        this.$currentTab.next(tab);
    }

    public async fetchTab(appKey: string, tabKey: string){
        const tab = (await this.httpClient.get(`/api/es/apps/${appKey}/tabs/${tabKey}`).toPromise()) as ITab2;
        tab.app = await this.appSettings.oCurrentApp.pipe(take(1)).toPromise()
        await this.appSettings.cacheTab(tab);
        return tab;
    }

    public async getCurrentTab(): Promise<ITab2> {
        return this.oCurrentTab.pipe(first()).toPromise();
    }
    public async getAllColumns(): Promise<IColumnSetting[]> {
        return this.oColumnSettings.pipe(first()).toPromise();
    }
    public async getDefaultColumns(): Promise<IColumnSetting[]> {
        return this.oDefaultColumns.pipe(first()).toPromise();
    }
    public async getColumnDictionary(): Promise<{ [key: string]: IColumnSetting }> {
        const cols = await this.getAllColumns();
        const dictionary: { [key: string]: IColumnSetting } = {};
        cols.forEach(col => dictionary[col.fieldName] = col);
        return dictionary;
    }
    public async getDefaultFacetColumns(): Promise<IColumnSetting[]> {
        return this.oDefaultFacetColumns.pipe(first()).toPromise();
    }
    public async getExcelDataMapper(): Promise<Factory<any>> {
        return { create: (row) => row.source };
    }

    public async translateToColumnList(fields: string[]): Promise<IColumnSetting[]> {
        const dictionary = await this.getColumnDictionary();
        return fields.map(f => dictionary[f]);
    }

    private whenCurrentTabIsSetLoadColumns() {
        this.subscriptions.push(
            this.oCurrentTab.subscribe(async currentTab => {
                const columns = await this.getColumns(currentTab);

                columns.map(col => {
                    if (col.isDynamicFacet == false) { col.type = 'text'; }
                    else {
                        col.type = this.translateType(col);
                    }
                    if (!!col.referenceLink) {
                        col.dataType = 'link';
                    }
                });
                this.$columnSettings.next(columns);
            })
        );
    }

    translateType(col: IColumnSetting) {
        const translateDataType = {
            string: 'text',
            int: 'numeric',
            integer: 'numeric',
            double: 'numeric',
            number: 'numeric',
            currency: 'numeric',
            percent: 'numeric',
            long: 'numeric',
            date: 'date',
            text: 'text',
            string_array: 'text',
            string_set: 'text',
            timestamp: 'date',
            timestamptz: 'date',
            link: 'text',
            phone: 'text',
            boolean: 'boolean'
        };
        return translateDataType[col.dataType];
    }


    public async getTabColumns(currentTab: ITab2): Promise<IColumnSetting[]> {
        const cols = await this.httpClient.get<IColumnSetting[]>(`/api/es/apps/${currentTab.app.key}/tabs/${currentTab.key}/columns`).toPromise();
        const mappedCols = cols.map(col => ({
            ...col, // Spread the properties of col
            type: this.translateType(col) // Add the type property
        }));
        console.log('mapped columns', mappedCols);
        return mappedCols;
    }

    private async getColumns(currentTab: ITab2) {

        if (!_tabSettingsCache[currentTab.key]) {
            _tabSettingsCache[currentTab.key] = await this.httpClient.get<IColumnSetting[]>(`/api/es/apps/${currentTab.app?.key}/tabs/${currentTab.key}/columns`).toPromise();
        }
        console.log("get columns", _tabSettingsCache[currentTab.key]);
        return _tabSettingsCache[currentTab.key];
    }

    public async updateTabColumns(tabId: string) {
        const app = await this.appSettings.getCurrentAppTabs();
        const currentTab = app.find(tab => tab.key == tabId);
        _tabSettingsCache[currentTab?.key] = await this.httpClient.get<IColumnSetting[]>(`/api/es/apps/${currentTab.app?.key}/tabs/${currentTab.key}/columns`).toPromise();
        this.$columnSettings.next(_tabSettingsCache[currentTab.key]);
        return _tabSettingsCache[currentTab.key];
    }
}


const _tabSettingsCache: { [key: string]: IColumnSetting[] } = {};


