<h6 class="title" [ngClass]="{'invalid': field.formControl.touched && to?.required && !uploadedFiles?.length}"
    *ngIf="field?.templateOptions?.label">{{field.templateOptions.label}} {{to?.required?'*':''}}</h6>
<div *ngIf="uploadedFiles" class="files-list">
    <div class="column">
        <div class="file column" *ngFor="let file of uploadedFiles">
            <div class="row header-details" *ngIf="file?.createdBy">
                <span style="font-weight: bold;">{{ file?.createdBy?.name}}</span>
                <span> {{ file?.createdOn | date}} </span>
            </div>
            <div class="row">
                <div class="download-area" (click)="download(file)">
                    <img class="file-icon" *ngIf="file?.type" [src]="getLogo(file?.type)">
                    <span> {{ file?.name }} </span>
                </div>
                <mat-spinner class="right-alignment" *ngIf="fileBeingDeleted == file.id" diameter="30"></mat-spinner>
                <button
                    *ngIf="(!fileBeingDeleted || fileBeingDeleted!==file.id) && !field.templateOptions.disabled && !indelibleFiles(file.id)"
                    class="delete-file-button" (click)="deleteAttachment(file)">
                    <i class="material-icons">
                        close
                    </i>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!disabled" class="box pr-2"
    [ngClass]="{'invalid': field.formControl.touched && to?.required && !uploadedFiles?.length}">
    <div *ngIf="!(singleMode && uploadedFiles?.length)" class="row drop-area" appDragDrop
        (onFilesDrop)="onFilesSelect($event)" (onFilesDragOver)="onFilesDragOver()"
        (onFilesDragLeave)="onFilesDragLeave()" [ngClass]="{'dragging': fileIsDragged}">
        <span class="text-secondary">Drop file{{singleMode?'':'(s)'}} here.</span>
    </div>
</div>

<div class="row bottom-bar">
    <button *ngIf="!(singleMode && uploadedFiles?.length) && !formControl.disabled && !isLoading" class="attach-button"
        (click)="fileInput.click()" [disabled]="">
        <i class="material-icons">
            attachment
        </i>
        <input hidden type="file" [multiple]="singleMode?false:true" [accept]="to.accept ? to.accept : null"
            #fileInput (change)="onFilesSelect($any($event)?.target?.files)">
    </button>
    <mat-spinner class="right-alignment" *ngIf="isLoading" diameter="30"></mat-spinner>
</div>
