<!-- <div *ngIf="to.label && !to.hidden" class="label">{{to.label}}</div> -->
<kendo-grid class="grid" #grid [data]="model" [resizable]="true" [trackBy]="trackByIndex" matTooltip="Click on row to start editing"
    (cellClose)="cellCloseHandler($event)"
    (cellClick)="cellClickHandler($event)"
    (remove)="removeHandler($event)"
    (save)="saveHandler($event)"
    (edit)="editHandler($event)"
    (cancel)="cancelHandler($event)"
    (add)="addHandler($event)">
    <kendo-grid-column *ngFor="let col of tableColumns" field="{{col.fieldName}}" [editor]="col.type" [width]="150"
        title="{{col.display}}"></kendo-grid-column>
    <kendo-grid-command-column  [width]="70">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
            <button class="dyn-table-remove-button" kendoGridRemoveCommand *ngIf="!isLastRow(rowIndex)"><mat-icon>delete</mat-icon></button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
