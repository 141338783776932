
    <div class="inset-0 overflow-hidden">


        <disco-details #details [requestId]="allDataRequestID" [appId]="appId" [tabId]="tabId"
            (context)="contextChanged($event)">

            <ng-template #headerTemplate let-context>
                <ng-container *ngIf="context?.data[0] as detailsData">


                    <div class="flex flex-row gap-4 justify-between items-start">


                        <h2 class="applicant-name text-3xl md:text-4xl mt-10 font-extrabold tracking-tight leading-7 sm:leading-10 truncate pr-5"
                            style="padding-top: 16px;" (click)="copyTitleWithLink()">
                            {{detailsData?.Name}}
                        </h2>



                        <!-- Rating From -->
                        <app-applicant-rating-form class="form star-rating mt-16" [model]="rowDetails"
                            (isSubmitted)="onSubmit(context)">
                        </app-applicant-rating-form>

                        <!-- Status From -->
                        <app-applicant-status-form class="form flex-1  mt-12" [model]="rowDetails"
                            (isSubmitted)="onSubmit(context)">
                        </app-applicant-status-form>

                    </div>

                    <!-- Tags  -->
                    <app-applicant-tags class="flex-1" [model]="rowDetails" (isSubmitted)="onSubmit(context)">
                    </app-applicant-tags>

                </ng-container>
            </ng-template>

            <ng-template #actionTemplate let-context style=" justify-content: space-between;">
                <ng-container *ngIf="false">
                    <app-disco-form-control title="EDIT" [form]="detailForm">
                    </app-disco-form-control>
                </ng-container>
            </ng-template>

            <ng-template #bodyTemplate let-context>
                <ng-container *ngIf="context?.data[0] as detailsData">
                    <div class=" h-full pt-6 main-content ">

                        <div class="tabs" style="display:flex;">
                            <mat-tab-group class="ml-2 mb-4" style="min-width: 75%;">
                                <mat-tab label="Profile" style=" width: 100%; display: flex; flex-direction: row;">
                                    <div class="card details">
                                        <mat-spinner *ngIf="isLoading" [diameter]="60" color="primary"></mat-spinner>

                                        <!-- Profile From -->
                                        <app-applicant-profile-form #detailForm formKey="applicant-profile" appId="ats"
                                            tabId="applicants" [data]="rowDetails" (isSubmitted)="onSubmit(context)"
                                            (isDeleting)="onDelete(context)"></app-applicant-profile-form>



                                    </div>
                                </mat-tab>

                                <mat-tab label="Resume">
                                    <mat-card class="card details resume">
                                        <iframe *ngIf="iframeResumeUrl && fileType ==='application/pdf'"
                                            [src]="iframeResumeUrl" width="100%" height="90%"></iframe>
                                        <app-applicant-resume class="form" [model]="rowDetails"
                                            (isSubmitted)="onSubmit(context)" (valueChanged)="onResumeUpload($event)">
                                        </app-applicant-resume>
                                    </mat-card>
                                </mat-tab>

                                <mat-tab class="flex-row" label="Interviews">

                                    <mat-card class="flex">

                                        <!-- applicant-second-interview From -->
                                        <app-interview-form formKey="applicant-second-interview" appId="ats"
                                            tabId="applicants" [model]="secondInterviewDetails"
                                            (isSubmitted)="onSubmit(context)"></app-interview-form>


                                    </mat-card>

                                    <mat-card class="flex card details" style="margin-top: 8px;">

                                        <!-- applicant-technical-interview From -->
                                        <app-interview-form formKey="applicant-technical-interview" appId="ats"
                                            tabId="applicants" [model]="technicalInterviewDetails"
                                            (isSubmitted)="onSubmit(context)"></app-interview-form>
                                        <div style="margin:-24px 20px 24px 20px;">
                                        </div>
                                    </mat-card>



                                </mat-tab>

                                <mat-tab label="Log" style="height: 675px; margin-top: 4px; overflow-y: scroll;">
                                    <div style="background-color: #FBFBFB;">
                                        <notes class="notes" [entityId]="applicantId" [showNoDataSection]="true"
                                            [notesListId]="logListId" [showTitle]="false" [viewOnlyMode]="true"
                                            [reloadNotes]="onReloadNotes">
                                        </notes>
                                    </div>
                                </mat-tab>

                            </mat-tab-group>

                            <div class="column"
                                style="width: 25%; margin-right: 8px; height: calc(85vh - 200px); overflow: auto;">
                                <mat-card class="m-4 p-0">
                                    <app-applicant-attachment class="attachment-form applicant-tags"
                                        [model]="rowDetails" (isSubmitted)="onSubmit(context)">
                                    </app-applicant-attachment>
                                </mat-card>
                                <mat-card class="m-4 p-0">
                                    <notes class="notes" [entityId]="applicantId" [notesListId]="discussionListId"
                                        [showTitle]="false" (listUpdated)="updateDiscussionListId($event)"> </notes>
                                </mat-card>


                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </disco-details>
    </div>
