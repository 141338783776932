import { Injectable, InjectionToken, Inject } from '@angular/core';

import { DRQuery, DRStat, DRFilter, ChartConfig, DRSortCriteria, IDRFilter } from '../classes';
import { FacetFieldAccumulator } from '../classes/facet-field-accumulator.class';
import {Observable, Subject, BehaviorSubject, Subscription, ReplaySubject} from 'rxjs';
import { IFilterSortDataService } from './ifilter-sort-data-service.service';

export const QUERY_NAME = new InjectionToken<string>('QueryName');

@Injectable()
export class DiscovererQueryService extends IFilterSortDataService<DRQuery>  {

    protected baseService: DiscovererQueryService;
    public updateSubscription: Subscription;

    get name(): string {
        return this._name;
    }
    set name(val: string) {
        this._name = val;
    }

    get changeId(): string {
        return !!this.baseService ? this.baseService.changeId + '-' + this._changeId.toString() : this._changeId.toString();
    }

    public oFacetResults: Observable<FacetFieldAccumulator[]>;
    public oSelectedStats: Observable<DRStat[]>;

    protected _sFacetResults: Subject<FacetFieldAccumulator[]>;
    protected _sSelectedStats: BehaviorSubject<DRStat[]>;
    protected _name: string;


    constructor(@Inject(QUERY_NAME) name: string = '') {
        super();
        this._name = name;
        this._sFacetResults = new Subject();
        this._sSelectedStats = new BehaviorSubject([]);

        this.oSelectedStats = this._sSelectedStats.asObservable();
        this.oFacetResults = this._sFacetResults.asObservable();
    }

    public results(facets: FacetFieldAccumulator[]) {
        this._sFacetResults.next(facets);
    }

    public refresh() {
        if (this._sortChanged) {
            this.updateQuery();
        } else if (this._filterChanged) {
            this.updateQuery();
        } else if (this._pageChanged) {
            this.updateQuery();
        } else if (this._groupChanged) {
            this.updateQuery();
        } else if (this._dataFlattenByChanged) {
            this.updateQuery();
        }
    }

    public defaultService() {
        return this.baseService?.defaultService() || this;
    }

    public createChildService(name?: string): DiscovererQueryService {
        const newService = new DiscovererQueryService(name);
        newService.baseService = this;
        newService.updateSubscription = this.oQuery.subscribe(query => {
            // copy service
            newService.updateQuery();
        });

        return newService;
    }

    public selectStat(stat: DRStat, index: number) {
        const next: DRStat[] = this._sSelectedStats.value;
        while (next.length <= index) {
            next.push(null);
        }
        next[index] = stat;
        this._sSelectedStats.next(next);
        this._changeId++;
    }

    public clearStatSelection() {
        this._sSelectedStats.next([]);
        this._changeId++;
    }

    protected getMergedFilter(): IDRFilter[] {
        if (!!this.baseService) {
            return IFilterSortDataService.createMergedFilter(Object.assign([], this.baseService.getMergedFilter()), this.filters);
        } else {
            return this.filters;
        }
    }

    protected getMergedSorts() {
        if (!!this.baseService) {
            return IFilterSortDataService.createMergedSort(Object.assign({}, this.baseService.getMergedSorts()), this.sorts);
        } else {
            return this.sorts;
        }
    }

    protected getMergedGroups() {
        if (!!this.baseService) {
            const parentGroups =  this.baseService.getMergedGroups();
            return parentGroups.concat(this.groups);
        } else {
            return this.groups;
        }
    }

    public getMergedDataFlattenBy() {
        if (!!this.baseService) {
            if (this.baseService.dataFlattenBy === '') {
                return this.baseService.getMergedDataFlattenBy();
            } else {
                return this.baseService.dataFlattenBy;
            }
        } else {
            return this.dataFlattenBy;
        }
    }

    private updateQuery() {
        const query = new DRQuery();
        const mergedFilters = this.getMergedFilter();
        const mergedSorts = this.getMergedSorts();
        const mergedGroups = this.getMergedGroups();
        query.filters = (Object.keys(mergedFilters).map(k => mergedFilters[k]) as DRFilter[]);
        //query.filters = mergedFilters;
        query.sorts = (Object.keys(mergedSorts).map(k => new DRSortCriteria(k, mergedSorts[k])) as DRSortCriteria[]);
        query.groups = mergedGroups && mergedGroups.length > 0 ? [mergedGroups[0]] : [];
        query.dataFlattenBy = this.getMergedDataFlattenBy();
        query.size = this._pageSize;
        query.start =  this.start > 0 ?  this.start - 1 : 0;
        query.changeId = this.changeId;
        this._sQuery.next(query);
    }
    public unSetFilter(filterName: string) {
        if (this.filters.find(x => x.fields[0] === filterName) || filterName.toLowerCase() === "search" ) {
            super.unSetFilter(filterName)
        } else {
            this.baseService?.unSetFilter(filterName)
        }
        return this;
    }

    public setFilter(filterName: string, filter: IDRFilter, addToEnd = true) {
        this.unSetFilter(filterName);
        super.setFilter(filterName, filter, addToEnd);
        return this;
    }

    public getAppliedFacetFilter(filterName: string) {
        return this.getMergedFilter().filter(x=>x.type==='facet' && x.fields[0]===filterName);
    }

    public getAppliedAdvancedFacetFilter(filterName: string) {
        return this.getMergedFilter().filter(x=>x.type==='All' && x.fields[0].includes('Advanced_Facet') && x.fields[0].split('|')[0] === filterName);
    }
}



