import {
    Component, Input, OnInit, ViewChild, ElementRef,
    ChangeDetectorRef, Output, EventEmitter, OnChanges, SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { INote, Note } from '../../models/note.model';
import { ConfirmDeletionDialogComponent } from './confirm-deletion-dialog/confirm-deletion-dialog';
import { UserDataServiceInterface, IUser } from '../../services/user-data-service-interface.service';
import { NotesServiceInterface } from '../../services/notes-service-interface.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { extend } from 'hammerjs';
import { BaseController } from '@discoverer/core';
import _ from 'lodash';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends BaseController implements OnInit, OnChanges {
    @ViewChild('scrollDown') private scrollContainer: ElementRef;

    @Output() attachmentsCount = new EventEmitter<number>();
    @Output() noteAdded = new EventEmitter<Note>();
    @Output() listUpdated = new EventEmitter<number>();

    @Input() reloadNotes: Observable<boolean>;

    @Input() entityId: number;
    @Input() set notesListId(value) { this._notesListId = value; this.getNotes() };
    @Input() viewOnlyMode = false;

    @Input() showNoDataSection = false;
    @Input() title = 'Notes & Attachments';
    @Input() isPrivate = false;
    @Input() showTitle = true;
    @Input() subTitle = 'Add note/attachment:';
    @Input() noteType;
    @Input() set disabled(value) {
        this._disabled = value;
        if (this.notesForm) {
            value ? this.notesForm.disable() : this.notesForm.enable();
        }
    }
    get disabled(): boolean {
        return this._disabled;
    }
    get notesListId() {
        return this._notesListId;
    }

    public notesForm: FormGroup;
    public notes: Note[] = [];
    public selectedFiles = [];
    public fileIsDragged = false;
    public currentUser: IUser;
    public noteIdToDelete;
    public addNoteLoading = false;
    public extendedView = true;
    public noteTypes = ['Note', 'Call', 'Email'];
    // tslint:disable-next-line:variable-name
    private _disabled = false;
    private _notesListId;

    constructor(
        private notesService: NotesServiceInterface,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private dialog: MatDialog,
        private userDataService: UserDataServiceInterface
    ) {
        super();

    }

    async ngOnInit() {
        this.notesForm = this.fb.group({
            body: '',
            attachment: ''
        });
        this.disabled ? this.notesForm.disable() : this.notesForm.enable();
        this.currentUser = await this.userDataService.getUser();
        await this.getNotes();
        this.subscriptions.push(this.reloadNotes?.subscribe(async x => {
            await this.getNotes();
        }));
    }

    async ngOnChanges(change: SimpleChanges) {
        if (!!change['notesListId']) {
            await this.getNotes();
        }
    }

    public cancel() {
        this.notesForm.controls.attachment.setValue(null);
        this.notesForm.controls.body.setValue(null);
        this.selectedFiles = [];
    }

    public async save() {
        this.addNoteLoading = true;
        const formData = this.appendData();
        const response = await this.notesService.addNote(formData);
        if (response) {
            await this.onNoteSave(response);
        }
        this.addNoteLoading = false;
    }

    public onFilesSelect(files, event?) {
        if (!this.disabled) {
            Object.keys(files).forEach(key => {
                this.selectedFiles.push(files[key]);
            });
            this.fileIsDragged = false;
            if (event) {
                event.target.value = '';
            }
            this.cd.markForCheck();
        }
    }


    public deleteAttachment(fileIndex) {
        this.selectedFiles.splice(fileIndex, 1);
    }

    public onFilesDragOver() {
        this.fileIsDragged = true;
    }

    public onFilesDragLeave() {
        this.fileIsDragged = false;
    }

    public downloadFile(attachment) {
        this.notesService.downloadAttachment(attachment.id).then(x => {
            const response = x;
            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = attachment.fileName;
            anchor.href = url;
            anchor.click();
        }).catch(x => { });
    }

    public async deleteNote(note: Note) {
        if (this.isMyNote(note.createdBy.id)) {
            const message = 'Are you sure you want to delete this note and the attached files?';
            const dialogRef = this.openConfirmationDialog(message);
            dialogRef.afterClosed().subscribe(async userConfirmed => {
                if (userConfirmed) {
                    this.noteIdToDelete = note.id;
                    this.notesService.deleteNote(note.id).subscribe(response => {
                        if (response) {
                            this.noteIdToDelete = null;
                            const index = this.notes.findIndex(n => n.id === response);
                            this.notes.splice(index, 1);
                            this.countAttachments();
                        }
                    });

                }
            });
        }
    }

    public async deleteOldAttachment(note, attachmentId) {
        if (this.isMyNote(note.createdBy.id)) {
            const message = 'Are you sure you want to delete this attachment?';
            const dialogRef = this.openConfirmationDialog(message);
            dialogRef.afterClosed().subscribe(async userConfirmed => {
                if (userConfirmed) {
                    this.notesService.deleteAttachment(attachmentId).subscribe(response => {
                        if (response) {
                            const noteIndex = this.notes.findIndex(n => n.id === note.id);
                            const attIndex = this.notes[noteIndex].attachments.findIndex(att => att.id === response);
                            this.notes[noteIndex].attachments.splice(attIndex, 1);
                            this.countAttachments();
                        }
                    });
                }
            });
        }
    }

    public getLogo(fileName: string) {
        const extension = fileName.split('.')[1].toLowerCase();
        return `assets/icons/${extension.toLocaleLowerCase()}.svg`;
    }

    public isMyNote(createdById: number): boolean {
        return +createdById === +this.currentUser.id;
    }

    private countAttachments() {
        let count = 0;
        this.notes.forEach(note => count += note.attachments ? note.attachments.length : 0);
        this.attachmentsCount.emit(count);
    }

    private openConfirmationDialog(message) {
        return this.dialog.open(ConfirmDeletionDialogComponent, {
            width: 'fit-content',
            height: 'fit-content',
            data: { message },
            autoFocus: false
        });
    }


    private async onNoteSave(saveResponse) {
        const note = new Note(saveResponse);
        this.notes.push(note);
        this.notesForm.get('body').setValue('');
        this.deleteAttachments();
        this.scrollToBottom();
        this.countAttachments();
        if (!this.notesListId) {
            this.listUpdated.emit(note.notesListId);
        }
        await this.notesService.addNoteNotification(note.id, this.entityId);
        this.noteAdded.emit(note);

        await this.getNotes();
        this.cd.markForCheck();
    }

    private deleteAttachments() {
        this.selectedFiles = [];
        this.notesForm.get('attachment').setValue('');
        this.cd.markForCheck();
    }

    private scrollToBottom(): void {
        try {
            setTimeout(() => {
                if (this.scrollContainer) {
                    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
                }
            }, 50);
        } catch (err) { }
    }

    private appendData(): FormData {
        const formData: FormData = new FormData();
        if (this.selectedFiles) {
            this.selectedFiles.forEach(file => {
                formData.append(`attachment`, file);
            });
        }
        formData.append('body', this.notesForm.get('body').value);
        formData.append('id', '0');
        formData.append('createdOn', new Date().toString(),);
        formData.append('isPrivate', this.isPrivate.toString());
        if (this.notesListId) {
            formData.append('notesListId', this.notesListId.toString());
        }
        if (this.noteType) {
            formData.append('type', this.noteType);
        }
        return formData;

    }

    public async getNotes() {
        if (this.notesListId) {
            const response = await this.notesService.getListNotes(this.notesListId);
            if (response && response.length) {
                this.notes = _.map(_.sortBy(response, 'createdOn'), note => new Note(note));
                this.notes.forEach(note => {
                    if (note.createdOn && !note.createdOn.toString().match(/GMT[+-]\d{4}/)) {
                        const dateTime = new Date(note.createdOn);

                        if (!isNaN(dateTime.getTime())) {
                            const dateTime = new Date(note.createdOn);
                            const localOffset = dateTime.getTimezoneOffset() * 60000;
                            const localDateTime = new Date(dateTime.getTime() - localOffset);
                            note.createdOn = localDateTime
                        }
                    }
                })
            }
            if (!this.cd['destroyed']) {
                this.cd.markForCheck();
            }
        } else
            this.notes = [];

    }

}
