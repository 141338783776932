import { HttpClient } from '@angular/common/http';
import {
    Component,
    Directive,
    Input,
    OnInit
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AdvancedFilterService,
    BaseController,
    DiscovererFacetTranslationService,
    DiscovererQueryService,
    DRFilter,
    FILTERING_OPERATION,
    IColumnSetting,
    IDRFilter,
    ITab2,
    ReportPersistService,
    TabSettingsService
} from '@discoverer/core/services';
import { AdvancedDialog } from '@discoverer/dynamic-reports/dialogs';
import { Apollo } from 'apollo-angular';
import { combineLatest, ReplaySubject } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

export class AdvancedFiltersDisplay {
    filters: Array<any>;
    type: string;
    constructor(advFilters) {
        this.filters = advFilters;
        this.type = advFilters[0].type;
    }
}
export class AppliedFilterDisplay {
    facet: IColumnSetting;
    facetTitle: string;
    facetValues: string[];
    openFacet?: boolean;
    isExclusion?: boolean;
    hidden?: boolean;
}

@Directive({})
export class AppliedFiltersDirective extends BaseController implements OnInit {
    @Input() queryService: DiscovererQueryService
    public columnDictionary: { [key: string]: IColumnSetting };
    public currentTab: ITab2
    public advFilterService: AdvancedFilterService;
    public $displayedAdvFilters: ReplaySubject<Array<AdvancedFiltersDisplay>> = new ReplaySubject(null);
    public $displayedFilters: ReplaySubject<Array<AppliedFilterDisplay>> = new ReplaySubject(null);
    public closeMenu: boolean = false;

    constructor(
        private dialog: MatDialog,
        private facetTranslationService: DiscovererFacetTranslationService,
        private http: HttpClient,
        private apollo: Apollo,
        private _tabSettings: TabSettingsService,
        public _reportPersistService: ReportPersistService,
    ) { super(); }

    async ngOnInit() {
        this.$displayedFilters.next([]);
        this.advFilterService = new AdvancedFilterService(this.facetTranslationService, this.http, this.apollo, this._tabSettings);
        this.currentTab = await this._tabSettings.getCurrentTab();
        this.columnDictionary = await this._tabSettings.getColumnDictionary();
        if (!!this.queryService && this.columnDictionary) {
            this.subscriptions.push(this.queryService.oQuery
                .pipe(map(s => this._mapAdvancedFiltersDisplay(s.filters)))
                .subscribe(this.$displayedAdvFilters));
        }
        this.subscriptions.push(
            combineLatest([this.queryService.oQuery, this._reportPersistService.mainViewState.oState])
                .pipe(map(combinedStates => this._mapCombinedStateResponse(combinedStates)))
                .subscribe(async filters => this.$displayedFilters.next(await filters))
            );

    }
    public trackByFields(index: number, filter: AppliedFilterDisplay) {
        return filter.facetTitle;
    }

    public async showAdvancedModal(index: number = 0) {
        const currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        const advFilter = (currentQuery.filters as DRFilter[]).filter(f => f.type == 'Any' || f.type == 'All')[0];
        const groups = (advFilter?.filters as DRFilter[]);
        const filterMode = advFilter?.type || 'All';

        const dialogRef: MatDialogRef<AdvancedDialog, boolean> = this.dialog.open(AdvancedDialog, {
            panelClass: 'advanced-dialog-container',
            data: {
                queryService: this.queryService,
                tabSettings: this._tabSettings,
                filterMode
            },
            width: '120vh',
            height: '90vh',
            autoFocus: false
        });
        const userSaved = await dialogRef.afterClosed().toPromise();
        this.handleDialogResponse(userSaved);
    }

    private handleDialogResponse(userSaved?: boolean) {
        if (userSaved) {
            this.queryService.refresh();
        }
    }

    public async removeAdvancedFilter(index: number) {
        console.log("remove filter", index);
        let currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        let advFilter = (currentQuery.filters as DRFilter[]).filter(f => f.type == 'Any' || f.type == 'All')[index];
        // let fieldName = (advFilter.filters as DRFilter[])[index].fields[0];

        this.queryService.unSetFilter('Advanced');
        this.queryService.refresh();
    }

    public async removeEmptyfacetFilters(event: boolean = false) {
        if (!event) {
            const currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
            const emptyFilters = currentQuery.filters.filter(f => f.type == 'facet' && f.expression.length === 0);
            if (emptyFilters.length > 0) {
                emptyFilters.forEach(fltr => this.queryService.unSetFilter(fltr.fields[0]));
                this.queryService.refresh();
            }
        }
    }

    private _mapAdvancedFiltersDisplay(filters: IDRFilter[]) {
        const advFilters = filters.filter(f => (f.type == 'Any' || f.type == 'All') && f.fields[0] === 'Advanced');
        return advFilters.length ? [new AdvancedFiltersDisplay(advFilters)] : [];
    }

    public mapFacetValues(facetValues) {
        return facetValues.map(facetValue => (facetValue.title) ? facetValue.title?.replace('|', '\t') : '(Blank)');
    }

    private _prepareFilters(combinedStates) {
        const pinnedFilters = combinedStates[1]?.facets.filter(facet => facet.isPinned);
        const appFilters = combinedStates[0]?.filters
            .filter(f => (f.type === 'facet' || f.fields[0].includes('Advanced_Facet')) && !f.hidden)
            .map(filter => { 
                let facetName = '';
                if(filter.type === 'facet') {
                    facetName = filter.fields[0];
                } else if(filter.fields[0].includes('Advanced_Facet')) {
                    facetName = filter.filters[0].fields[0];
                }
                return { ...filter, name: facetName, isExclusion: filter?.isExclusion} 
            });
        const filteredPF = pinnedFilters.filter(pf => !appFilters.find(f => f.fields[0] === pf.name));
        return [...filteredPF, ...appFilters];
    }

    private async _mapCombinedStateResponse(combinedStates) {
        const filters = this._prepareFilters(combinedStates);
        const appliedFilters = await this.$displayedFilters?.pipe(take(1)).toPromise();
        this._removeDeletedFilters(appliedFilters, filters);
        filters.forEach(filter => {
            const facet = this.columnDictionary[filter.name];
            const isExclusion = filter.facetValues?.some(x => x.isExclusion);
            let facetValues;
            if(!!filter.fields && filter.fields[0].includes('Advanced_Facet')) {
                this.advFilterService.init(filter, this.columnDictionary);
                const advValue = this.advFilterService.valueFromExpressions(filter.filters[0]);
                const advOperation = this.advFilterService.operationFromExpression(filter.filters[0].expression[0], filter.filters[0].fields[0]);
                const advOperationName = FILTERING_OPERATION.filter((o) => o.id === advOperation)[0].label;
                facetValues = [advOperationName, advValue];
            } else {
                facetValues = filter.facetValues?.length > 0 ? this.mapFacetValues(filter.facetValues) : [];
            }
            this._updateAppliedFilters(appliedFilters, facet, facetValues, filter.openFacet, isExclusion);
        });
        return appliedFilters;
    }
    private _updateAppliedFilters(appliedFilters, facet, facetValues, openFacet, isExclusion) {
        const filterIndex = appliedFilters.findIndex(appliedFilter => appliedFilter?.facet?.fieldName == facet?.fieldName);
        if (filterIndex >= 0) {
            appliedFilters[filterIndex].facetValues = facetValues;
            appliedFilters[filterIndex].isExclusion = isExclusion;
        } else {
            appliedFilters.push({
                facet, facetValues, openFacet, isExclusion, facetTitle: facet?.display
            });
        }
    }

    private _removeDeletedFilters(appliedFilters, filters) {
        const removedFilters = appliedFilters.filter(appliedFilter => !filters.some(filter => appliedFilter.facet.fieldName === filter.name));
        removedFilters.forEach(filter => {
            const filterIndex = appliedFilters.findIndex(f => f.facet.fieldName === filter.facet.fieldName);
            (filterIndex >= 0) && appliedFilters.splice(filterIndex, 1);
        });
    }

}
