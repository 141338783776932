import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAppResponse } from '@app/ATS/models/app-response';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorCode } from '@app/ATS/models/enums';


@Injectable()
export class ApiCallerService {
    constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

    public get<T>(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
        return this.http.get<IAppResponse<T>>(url, { headers, params }).pipe(
            map((response: IAppResponse<T>) => this.handleResponse(response)),
           // catchError(this.handleError)
        );
    }

    public post<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
        return this.http.post<IAppResponse<T>>(url, body, { headers }).pipe(
            map((response: IAppResponse<T>) => this.handleResponse(response)),
           // catchError(this.handleError)
        );
    }

    public delete<T>(url: string, body?: any, headers?: HttpHeaders): Observable<T> {
        return this.http.delete<IAppResponse<T>>(url, { body: body, headers: headers }).pipe(
            map((response: IAppResponse<T>) => this.handleResponse(response))
        );
    }

    public put<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
        return this.http.put<IAppResponse<T>>(url, body, { headers }).pipe(
            map((response: IAppResponse<T>) => this.handleResponse(response)),
            // catchError(error => {
            //     return throwError(error);
            // }

//)
        );
    }

    private handleResponse<T>(response: IAppResponse<T>): any {

        if (response.errorCode !== ErrorCode.Success) {

            if (response.errorCode === ErrorCode.InternalServerError)
                throw { error: 'Something went wrong, please try again later!' }
            else
                throw { error: response.data }
        }

        return response.data;
    }
    private handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Client-side error: ${error.error.message}`;
        } else {
            errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }
        this.snackBar.open(errorMessage, 'Close', { duration: 3000 });

        return ;
    }
}