import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { TabSettingsService } from "./tab-settings.service";

@Injectable({
    providedIn: 'root'
})
export class MultiSelectService {
    public isDisabled = new BehaviorSubject(true);
    public oAllItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public isMultiSelectHeaderCellEnabled = new BehaviorSubject(false);
    public itemsSelected = [];
    public iteamsId = [];
    public tabBusinessKey;
    public defaultColumns;
    constructor() {
    }

    public setTabBusinessKey(tabBusinessKey) {
        this.tabBusinessKey = tabBusinessKey;
    }
    public setItemsSelected(objectChecked, tab = '') {
        if (this.iteamsId.includes(objectChecked[this.tabBusinessKey])) {
            this.itemsSelected = this.itemsSelected.filter(obj => obj[this.tabBusinessKey] !== objectChecked[this.tabBusinessKey])
            this.iteamsId = this.iteamsId.filter(obj => obj !== objectChecked[this.tabBusinessKey])
        } else {
            if (tab) {
                objectChecked.tab = tab;
            }
            this.itemsSelected.push(objectChecked);
            this.iteamsId.push(objectChecked[this.tabBusinessKey])
        }
        this.oAllItems.next(this.itemsSelected);
        this.isDisabled.next(this.iteamsId.length == 0);
    }

    public setListOfItems(item: any[]) {
        item.forEach(item => {
            this.setItemsSelected(item);
        })
    }

    public isItemChecked(objectChecked) {
        return this.iteamsId.includes(objectChecked[this.tabBusinessKey]);
    }

    public getItemsSelected() {
        return this.itemsSelected;
    }


    public markListEmpty() {
        this.itemsSelected = [];
        this.iteamsId = [];
        this.isMultiSelectHeaderCellEnabled.next(false);
        this.isDisabled.next(true);
    }

}
