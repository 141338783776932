<div class="flex flex-col gap-4 text-2xl">
    <div class="message">
        <span>
            {{data.message}}
        </span>
    </div>
    <div class="flex flex-row ml-auto gap-4">
        <button mat-raised-button color="primary" (click)="close(true)">
            YES
        </button>
        <button mat-raised-button (click)="close(false)">
            NO
        </button>
    </div>
</div>
